<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import geojson from "@/assets/geojson/chongqing.json"
export default {
  name: "mapControl",
  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "56f21d0b10d59d272acc609098c30fbf",
      };
      AMapLoader.reset();
      AMapLoader.load({
        key: "23d95d6620fa5bd2e8da3f61be5de237",
        version: "2.0",
        plugins: [
          "AMap.Scale",
          "AMap.MouseTool",
          "AMap.Marker",
          "AMap.ContextMenu",
          "AMap.Polygon",
          "AMap.GeoJSON",
        ],
      })
        .then(async (AMap) => {
          
          this.$get("/deviceManage/getDeviceInfos").then(async (res) => {

            if (res.data.state == "success") {
              let centerPos = '';
              const data = res.data.datas
              data.forEach(v=>{
                if (v.deviceType == 2&&v.deviceLocation){
                  centerPos= v.deviceLocation
                }
              })
              const lat = centerPos.split(",");
              this.map = new AMap.Map("container", {
                // 设置地图容器id
                viewMode: "3D", // 是否为3D地图模式
                zoom: 16.5, // 初始化地图级别
                layers: [new AMap.TileLayer.Satellite()],
                center: [lat[0], lat[1]], // 初始化地图中心点位置
              });

              let dataGeo = []
                await new AMap.GeoJSON({
                    geoJSON: geojson,
                    getPolyline: function (geojson, lnglats) {
                        dataGeo = lnglats
                    }
                });
                //console.log('dataGeo',dataGeo)
              if(centerPos){
                this.setMapMarker(dataGeo, centerPos);
              }
            }
          });
        })
        .catch((e) => {
          //console.log(e);
        });
    },
    setMapMarker(polygonWkt, centerPos) {
        // 绘制多边形
      const polygon = new AMap.Polyline({
        path: polygonWkt, // 设置多边形坐标数组
        strokeColor: "#0bc0b8", // 线条颜色
        strokeWeight: 4, //线条宽度
        fillColor: "#000", // 填充颜色
        fillOpacity: 1, // 填充透明度
      });
      polygon.setMap(this.map);
      // 绘制标记
      const lat = centerPos.split(",");
      if(lat[1]){
        const marker = new AMap.Marker({
          position: new AMap.LngLat(lat[0], lat[1]),
          offset: new AMap.Pixel(-15, -25),
          icon: require("@/assets/aggroTrace/control.png"),
          zooms: [5, 18],
        });
        this.map.add(marker);
      }else{
        //console.log("未设置中心点")
        return;
      }

    },
  },
};
</script>
<style scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
}
</style>
