<template>
  <div class="weather">
    <div class="weather-top">
      <i :class="`icon qi-${weather.iconDay}`"></i>
      <div class="middle">
        <div class="text">
          <span>{{ weather.textDay }}</span>
          <span>{{ weather.windDirDay }}</span>
        </div>
        <div class="number">{{ weather.tempMin }}℃~{{ weather.tempMax }}℃</div>
      </div>
      <div class="right">
        <img src="@/assets/aggroTrace/location.png" alt="" /> {{ city }}
      </div>
    </div>
    <div class="weather-bottom">
      <div class="weather-bottom-item">
        <div class="left">{{ weather.windDirDay }}</div>
        <div class="right">{{ weather.windScaleDay }}级</div>
      </div>
      <div class="weather-bottom-item">
        <div class="left">大气压强</div>
        <div class="right">{{ weather.pressure }}hPa</div>
      </div>
      <div class="weather-bottom-item">
        <div class="left">能见度</div>
        <div class="right">{{ weather.vis }}公里</div>
      </div>
      <div class="weather-bottom-item">
        <div class="left">湿度</div>
        <div class="right">{{ weather.humidity }}%</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        
      weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
      weather: {},
      city:"",
      county:""
    };
  },
  created(){
    this.getPowerName()
  },
  methods: {
    getPowerName(){
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if(res.data.state == 'success'){
          this.city = res.data.datas[0].city;
          this.county = res.data.datas[0].county;
          this.requestLookup()
        }
      })
    },
    // 天气
    requestLookup() {

      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: {
          adm: this.city,
          location: this.county,
          key: this.weatherKey,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let { location } = res.data;
          this.getWeather(location);
        }
      });
    },
    // 实时天气
    getWeather(location) {
      this.$axios({
        method: "GET",
        url: "https://devapi.qweather.com/v7/weather/3d",
        params: { location: location[0].id, key: this.weatherKey },
      }).then((res) => {
        if (res.data.code == 200) {
          this.weather = res.data.daily[0];
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.weather {
  height: calc(100% - 60px);
  margin: 10px 3%;
  border-radius: 10px;
  background-image: linear-gradient(
    to right,
    rgba(35, 98, 115, 0.24),
    rgba(35, 98, 115, 0)
  );
  border: 1px solid #146769;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 8%;
    border-bottom: 1px solid #146769;
    img {
      width: 35px;
      height: 35px;
    }
    .middle {
      font-size: 18px;
      .text {
        color: #fff;
        margin-bottom: 6px;
      }
      .number {
        color: #0bc0b8;
      }
    }
    .right {
      font-size: 22px;
      color: #fff;
      img {
        width: 26px;
        height: 28px;
        vertical-align: middle;
      }
    }
  }
  &-bottom {
    padding: 15px 3.5% 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      width: 48.5%;
      margin-bottom: 15px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 2px;
      .left {
        width: 50%;
        background: rgba(12, 88, 84, 0.35);
        font-size: 14px;
        color: #b1e1ff;
      }
      .right {
        width: 50%;
        background: #146769;
        font-size: 18px;
        color: #fff;
      }
    }
  }
}
.icon {
  font-size: 40px;
}
</style>
