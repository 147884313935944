<template>
  <div class="indexScreen" id="screenTotal">
    <div id="myMap">
      <mapControl></mapControl>
    </div>

    <HeaderTop title="灌溉控制大屏"></HeaderTop>

    <div id="main">
      <div id="mainBox">
        <div id="lefts" style="left: 3%">
          <div class="container-item-box1">
            <boxTitle text="灌溉控制设备"></boxTitle>
            <dataSelect :optionList="baseList" v-model="base" @onSelectChange="handlerSelect"></dataSelect>
            <div class="base">
              <div
                class="base-item"
                v-for="item in irrigationList"
                :key="item.id"
              >
                <div>{{ item.name }}</div>
                <div class="switch">
                  <span @click="changStatus('255', item.dataPointRelId)"
                    ><img :src="switchList[0]" /><i class="open">开启</i>
                  </span>
                  <span @click="changStatus('00', item.dataPointRelId)"
                    ><img :src="switchList[1]" /><i class="close">关闭</i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="rights" style="right: 3%">
          <div class="container-item-box3">
            <boxTitle text="气象信息"></boxTitle>
           <Weather></Weather>
          </div>
          <div class="container-item-box3">
            <boxTitle text="土壤监测数据"></boxTitle>
            <dataSelect :optionList="soilList" v-model="soildName" @onSelectChange="chooseEqment"></dataSelect>
            <div class="solid">
              <div class="equitment" v-for="(item, i) in soildObj" :key="i">
                <div class="text-gray">{{ item.name }}</div>
                <div class="text-white">
                  <span class="number">{{ item.data }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="container-item-box2">
            <boxTitle text="监控摄像头"></boxTitle>
            <dataSelect  v-if="videoList.length>0" :optionList="videoList" v-model="video" @onSelectChange="chooseVideo"></dataSelect>
            <div class="carouselBig">
              <video
                v-if="videoUrl"
                id="my-video"
                ref="myVideo"
                class="video-js vjs-default-skin box"
                controls
                preload="auto"
                loop
                muted
                style="height: 100%; width: 100%; object-fit: fill"
              >
                <source :src="videoUrl" type="rtmp/flv" />
              </video>
              <div v-else>暂无监控</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderTop from "@/components/breadCrumb/HeaderTop.vue";
import boxTitle from "@/components/breadCrumb/boxTitle.vue";
import Weather from "@/views/components/weather.vue";
import mapControl from "./mapControl.vue";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";

export default {
  components: { HeaderTop, boxTitle, mapControl,Weather },
  data() {
    return {
      baseList: [],
      base: "",
      switchList: [
        require("../../../../assets/index/open.png"),
        require("../../../../assets/index/close.png"),
      ],
      videoUrl: "",
      SecretObj: {},
      irrigationList: [],
      soildName: "",
      soilList: [],
      allwater: [],
      deviceNumber: null,
      baseObj: {},
      soildObj: {},
      videoList:[],
      video:'',
      myPlayer: null,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getBaseList();
      this.getSecret();
    },
    handlerSelect(val) {
      this.baseList.forEach((item) => {
        if (val == item.id) {
          this.baseObj = item;
          // this.getOverview(this.baseObj);
          this.baseGEtData(this.baseObj.baseManagementToDeviceBeanList);
        }
      });
    },
    baseGEtData(baseList) {
      let list = baseList;
      if (list.length) {
        this.deviceNumber = list[0].deviceNumber;
        this.getVillage(list[0].deviceNumber);
      }
    },
    // 查询基地列表
    getBaseList() {
      const data = {
        page: 1,
        size: 100,
      };
      this.$post("/baseManagement/getBaseManagements", data).then((res) => {
        if (res.data.state == "success") {
          this.baseList = res.data.datas.map(v=>{
            return{
              label: v.baseName,
              value: v.id
            }
          });
          this.base = res.data.datas[0].id;
          if (res.data.datas.length) {
            this.baseObj = res.data.datas[0];
            this.baseGEtData(this.baseObj.baseManagementToDeviceBeanList);
            let cameralist = JSON.parse(res.data.datas[0].cameraDevice);
            this.$get('/cameraManage/page',{ page:1,size:100}).then(res=>{
                if(res.data.state == 'success'){
                  res.data.datas.forEach(item => {
                    cameralist.forEach(v=>{
                      if(v==item.id){
                        this.videoList.push({
                          label: item.deviceName,
                          value: v
                        })
                      }
                    })
                 });
                 if(this.videoList.length>0){
                  this.video = this.videoList[0].value
                  this.getVideoUrl(this.videoList[0]);
                }
                }
              })
            
          }
        }
      });
    },
    getSecret() {
      this.$get("/deviceCtrl/getSecret").then((res) => {
        if (res.data.state == "success") {
          this.SecretObj = res.data.data;
          this.getPointInfoFor(res.data.data.deviceNumber);
        }
      });
    },
    changStatus(msg, id) {
      this.$get(`/deviceCtrl/dataPoint`, {
        deviceNum: this.SecretObj.deviceNumber,
        dataPointRelId: id,
        msg,
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message.success(msg == "255" ? "开启成功" : "关闭成功");
        }
      });
    },
    // 灌溉控制
    getPointInfoFor(deviceNumber) {
      this.$get("/deviceCtrl/pageDataPointInfoForCusdeviceNo", {
        page: 1,
        size: 100,
        deviceNum: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          this.irrigationList = res.data.data.cusdeviceDataPointList;
        }
      });
    },
    chooseEqment(val) {
      this.getDeviceData(val, 3);
    },
    chooseVideo(val){
      this.getDeviceData(val);
    },
    // 获取设备信息
    getVillage() {
      this.$get("/deviceManage/getDeviceInfos", { page: 1, size: 100 }).then(
        (res) => {
          if (res.data.state == "success") {
            //console.log(res.data.datas, "====供货");
            res.data.datas.forEach((item) => {
              const d = item.deviceNum;
              this.allwater[d] = item;
              if (item.deviceType === "3") {
                //土壤
                this.soilList.push({
                  label: item.deviceName,
                  value: item.deviceNum
                });
              }
            });
            //console.log(this.soilList, "allwater");
            if (this.soilList.length) {
              this.soildName = this.soilList[0].value;
              this.getDeviceData(this.soilList[0].value);
            }
          }
        }
      );
    },
    // 根据设备号获取设备数据
    getDeviceData(dev) {
      this.$get("/jxct/getDeviceInfo", {
        page: 1,
        size: 100,
        deviceNumber: dev,
      }).then((res) => {
        if (res.data.state == "success") {
          let datas = res.data.data;
          let keys = datas.type.split("/");
          let data = datas.data.split("|");
          let dataList2 = [];
          //console.log(keys, data);
          for (let i = 0; i < keys.length; i++) {
            dataList2.push({ name: keys[i], data: data[i] });
          }
          //console.log(dataList2);
          this.soildObj = dataList2;
        }
      });
    },
    //获取摄像头url
    getVideoUrl(deviceNumId) {
      this.$get(`/cameraManage/realPlay/${deviceNumId}?protocol=2`).then(
        (res) => {
          if (res.data.state == "success") {
            //console.log(res.data.datas, "====获取摄像头地址");
            this.videoUrl = res.data.data.url;
            // this.$refs.myVideo.src=res.data.data.url
            const that = this;
            setTimeout(() => {
              that.lookCamera(res.data.data.url);
            }, 3000);
          }
        }
      );
    },
    lookCamera(src) {
      this.$nextTick(() => {
        var myVideo = this.$refs.myVideo;
        this.myPlayer = videojs(myVideo, {
          controls: true, //控件显示
          bigPlayButton: true,
          autoplay: "muted",
          preload: "auto",
          hls: true,
        });
        this.myPlayer.src({
          src: src,
          type: "application/x-mpegURL",
        });
        //this.myPlayer.play();
        // this.videoArr.push(this.myPlayer);
      });
    },
   
  },
};
</script>
<style src="../../../../style/font/font.css" scoped></style>
<style scoped lang="less">
  @import '../../../../style/font/screen.less';
</style>
<style lang="scss" scoped>
#lefts,
#rights {
  width: 25%;
  height: calc(100vh - 80px);
  position: absolute;
  z-index: 47;
  bottom: 0;
  pointer-events: all;
  .container-item {
    position: absolute;
    width: 28%;
    height: calc(100vh - 80px);
    top: 90px;
    &-box1 {
      height: 94%;
      position: relative;
      .base {
        display: flex;
        flex-wrap: wrap;
        height: 94%;
        justify-content: space-around;
        padding: 10px;
        overflow: auto;
        &-item {
          padding: 25px 16px;
          width: 1rem;
          margin-bottom: 5px;
          height: 0.6rem;
          background: url("../../../../assets/index/guan.png") no-repeat;
          .switch {
            padding-top: 15px;
            span {
              display: inline-block;
              width: 66px;
              height: 20px;
              margin-right: 14px;
              line-height: 24px;
              text-align: center;
              cursor: pointer;
            }
            i {
              position: relative;
              top: -32px;
            }
            .open {
              left: 8px;
            }
            .close {
              left: -8px;
            }
          }
        }
      }
    }
    &-box3 {
      height: 32%;
      position: relative;
  
      .solid {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        padding: 10px;
        overflow: auto;
        height: calc(100% - 80px);
        .equitment {
          width: 205px;
          height: 40px;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          margin-right: 20px;
          div {
            flex: 1;
            text-align: center;
          }
          .text-gray {
            font-size: 15px;
            color: #b1e1ff;
            background: url(../../../../assets/index/equitment.png) no-repeat 100%
              100%;
          }
          .text-white {
            background: url(../../../../assets/index/equitment-right.png) no-repeat
              100% 100%;
          }
          .number {
            font-size: 18px;
            color: #fefefe;
          }
          .unit {
            font-size: 16px;
            color: #aac2d2;
          }
        }
        .equitment:last-child {
          // width: 300px;
          .number {
            font-size: 16px;
          }
        }
      }
    }
    &-box2 {
      height: 36%;
      position: relative;
      .carouselBig {
        margin: 10px 4%;
        height: calc(100% - 100px);
        background-color: #0e343e;
        border: 0.00521rem solid #17504d;
        border-radius: 10px;
      }
    }
  }
}
</style>
